@import "../base/variables";

.color-primary {
    color: $color-primary !important;
}
.color-grey {
    color: $color-grey !important;
}

.no-border {
    border: none !important;
}

.bg-transparent {
    background-color: transparent !important;
    background: transparent !important;
}

.scroll-x-without-bar {
    overflow-x: scroll !important;
    scrollbar-width: thin;
    scrollbar-color: transparent;
}
.scroll-x-without-bar::-webkit-scrollbar {
    height: 0;
    background-color: transparent;
}
.scroll-x-without-bar::-webkit-scrollbar-track {
    background-color: transparent;
}
.scroll-x-without-bar::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
}

.scroll-y-without-bar {
    overflow-y: scroll !important;
    scrollbar-width: thin;
    scrollbar-color: transparent;
}
.scroll-y-without-bar::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}
.scroll-y-without-bar::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
}
.scroll-y-without-bar::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
}