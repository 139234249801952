@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import "./mixin";

html,
body {
    padding: 0;
    margin: 0;

    font-family: "Open sans", sans-serif;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.container {
    @include container;
}
