// COLORS
$color-primary: #FE991C;
$color-black: #000000;
$color-dark: #262626;
$color-dark-grey: #313137;
$color-grey: #6B7280;
$color-light-grey: #afafaf;
$color-red: #ff3647;
$color-brown: #B1814B;
$color-yellow: #FE991C;
$color-dark-green: #118C52;
$color-green: #108C00;
$color-purple: purple;
$color-pink: #E45CBE;
$color-dark-blue: rgb(44, 78, 132);
$color-blue: #17C2D7;
$color-light: #EBF0F5;
$color-white: #ffffff;


// BREAK POINTS
$mobile: 575px;
$tablet: 767px;
$small-desktop: 991px;
$desktop: 1199px;
$large-desktop: 1400px;