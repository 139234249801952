@import "./variables";

// MEDIA QUERIES
@mixin mobile {
    @media screen and (max-width: $mobile) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet) {
        @content;
    }
}

@mixin small-desktop {
    @media screen and (max-width: $small-desktop) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: $desktop) {
        @content;
    }
}

@mixin large-desktop {
    @media screen and (min-width: $large-desktop) {
        @content;
    }
}

@mixin container {
    @include large-desktop {
        width: 1320px;
    }

    @include desktop {
        width: 960px;
    }

    @include small-desktop {
        width: 720px;
    }

    @include tablet {
        width: 540px;
    }

    @include mobile {
        width: 100%;
    }

    width: 1140px;
    margin: auto;
}