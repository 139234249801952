@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixin";

.legale-pdf-thumbnail-viewer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: relative;

    .legale-pdf-viewer {
        border: none;
        margin-bottom: 0;
        box-shadow: 0 0 5px 1px rgba($color-black, .2);
        position: relative;
        overflow: scroll;
        
        .legale-pdf-loading {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.legale-pdf-reader-wrapper {
    @include small-desktop {
        padding-right: 0;
    }

    position: relative;

    padding-right: 260px;

    .main-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        background-color: $color-white;
        border-radius: 15px;

        .toolbar {
            @include tablet {
                justify-content: space-between;
            }

            width: 100%;
            height: 50px;

            position: relative;

            padding: 15px;

            border-radius: 15px 15px 0 0;

            display: flex;
            justify-content: center;
            align-items: center;

            .pdf-info {
                display: flex;
                justify-content: center;
                align-items: center;

                .divider {
                    height: 18px;
                    margin: 0 20px;
                }

                .scale-info {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .scale-icon {
                        &.disabled {
                            opacity: .6;
                            cursor: not-allowed;
                        }
                    }
                }
            }

            .action-box {
                @include tablet {
                    position: relative;
                    right: 0;
                }

                display: flex;
                justify-content: center;
                align-items: center;

                position: absolute;
                right: 20px;
                top: 0;
                bottom: 0;

                font-size: 17px;
            }
        }

        .pdf-content {
            border-radius: 0 0 15px 15px;
            overflow: hidden;

            .legale-pdf-viewer {
                @include tablet {
                    width: calc(100vw - 40px);
                }

                position: relative;

                width: 600px;

                overflow: scroll;

                &::-webkit-scrollbar {
                    height: 0;
                    width: 0;
                    background-color: transparent;
                }

                .signature-position-wrapper {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    .react-draggable {
                        width: 170px;
                        height: 70px;

                        font-size: 12px;
                        border-radius: 4px;
                        transition: 0s all;

                        .draggable-item {
                            width: 100%;
                            height: 100%;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            text-align: center;
                            color: $color-white;
                            background-color: rgba($color-dark, .3);

                            padding: 5px 10px;
                            overflow: hidden;
                            pointer-events: none;

                            border-radius: 4px;

                            &.small {
                                padding: 1px;
                                font-size: 8px;
                                border-radius: 1px;
                            }
                        }
                    }
                }
            }
        }

        .legale-pdf-loader {
            width: 600px;
            height: 800px;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            border: 2px $color-yellow solid;
            background-color: $color-white;
        }
    }

    .preview {
        @include small-desktop {
            display: none;
        }

        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;

        width: 240px;

        border-radius: 15px;
        overflow: auto;

        .title {
            width: 100%;
            height: 50px;

            padding: 20px;

            display: flex;
            align-items: center;
        }

        .previews {
            height: calc(100% - 50px);

            padding: 20px;

            &::-webkit-scrollbar {
                width: 0;
                background-color: transparent;
            }

            .legale-pdf-viewer-thumbnail {
                cursor: pointer;
                
                border: 1px solid transparent;
                border-radius: 6px;
                overflow: hidden;

                margin-bottom: 20px;

                &.current-page {
                    border-color: $color-yellow;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .pagination-box {
        @include small-desktop {
            display: block;

            margin-top: 15px;
        }
        display: none;
    }

    &.full-screen {
        width: 100vw;
        height: 100vh;

        position: fixed;
        top: 0;
        left: 0;

        background-color: $color-light;
        z-index: 1001;
        overflow-y: scroll;
        padding: 50px 0;

        display: flex;
        align-items: center;

        .main-content {
            width: 100vw;

            margin: auto;

            border-radius: 0;

            .toolbar {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;

                border-radius: 0;
            }

            .pdf-content {
                border-radius: 0;

                .legale-pdf-viewer {
                    width: 100vw;
                }
            }
        }

        .preview {
            display: none;
        }

        .pagination-box {
            display: block;

            position: fixed;
            left: 0;
            right: 0;
            bottom: 9px;
        }
    }
}

.legale-pdf-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin: 0 5px;
    }

    button {
        margin: 0 5px;
        font-size: 16px;
    }
}